import Street from '@/components/structures/street'
// test
/* class Address {
  constructor () {
    this.id = 25225
    this.building_id = 35645
    this.building_number = '1'
    this.is_main = true
    this.street = new Street()
  }
} */

class Address {
  constructor () {
    this.id = null
    this.building_number = ''
    this.is_main = true
    this.street = new Street()
  }
}

export default Address
