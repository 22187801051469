import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  computed: {
    formatTitleAdvert () {
      let titleAdvert = this.detail.room_count + ' - комн. ' +
        this.formatValue('advert.flat_type', this.detail.flat_type) + ' - ' +
        this.detail.area_common + ' м2  ' + this.formatValue('building.district_custom', this.detail.building.district_custom) + ' ' +
        this.detail.building.mainBuildingAddress.street.name
      switch (this.$route.name) {
        case 'sale':
          titleAdvert = 'Продается ' + titleAdvert
          break
        case 'saleDetail':
          titleAdvert = 'Продается ' + titleAdvert
          break
        case 'lease':
          titleAdvert = 'Сдается ' + titleAdvert
          break
        case 'leaseDetail':
          titleAdvert = 'Сдается ' + titleAdvert
          break
      }
      return titleAdvert
    }
  }
}
