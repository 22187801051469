<template>
  <Btn
    is-type-icon
    :icon="icon"
    @click="handleClickFavorites"
    :color-icon="btnColor"
  />
</template>

<script>
import Btn from '@/components/base/Btn'

export default {
  name: 'FavoritesBtn',
  components: { Btn },
  props: {
    item: Object
  },
  computed: {
    icon () {
      return this.$store.getters['favorites/isExist'](this.item.id) ? 'mdi-cards-heart' : 'mdi-cards-heart'
    },
    btnColor () {
      return this.$store.getters['favorites/isExist'](this.item.id) ? 'red' : 'black'
    }
  },
  methods: {
    handleClickFavorites () {
      if (this.$store.getters['favorites/isExist'](this.item.id)) {
        this.$store.commit('favorites/remove', this.item.id)
      } else {
        this.$store.commit('favorites/add', this.item)
      }
    }
  }
}
</script>

<style scoped>

</style>
