<template>
  <v-progress-linear
    indeterminate
    color="primary"
    top
    height="6"
  ></v-progress-linear>
</template>

<script>
export default {
  name: 'Progress'
}
</script>

<style scoped>

</style>
