<template>
  <div class="mb-4 max-w-3xl mx-auto">
    <div id="productCarousel"
         class="f-carousel">
      <div
        v-for="(image) in sortedImages"
        :key="image.id"
        class="f-carousel__slide"
        :data-thumb-src="image.thumbnail"
        data-fancybox="gallery"
        :data-src="image.src"
      >
        <v-img
          alt=""
          class="mr-auto ml-auto"
          :src="image.src"
          :max-width="'100vh'"
          :max-height="'80vh'"
          data-lazy-src="@/assets/gray.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Fancybox } from '@fancyapps/ui/'
import '@fancyapps/ui/dist/carousel/carousel.css'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

import { Thumbs } from '@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js'
import '@fancyapps/ui/dist/carousel/carousel.thumbs.css'
import ru from '@/messages/ru/fancybox.js'

export default {
  name: 'FancyBox',
  props: {
    options: Object,
    images: Array
  },
  data () {
    return {
      sortedImages: []
    }
  },
  mounted () {
    this.sortedImages = this.images.sort((a, b) => {
      if (b.isMainAdvertImage) return 1
      if (a.isMainAdvertImage) return -1
      return b.is_main - a.is_main
    })

    // eslint-disable-next-line no-new
    new Carousel(
      document.getElementById('productCarousel'),
      {
        infinite: true,
        Dots: false,
        Thumbs: {
          type: 'classic',
          Carousel: {
            slidesPerPage: 1,
            Navigation: true,
            center: true,
            fill: true,
            dragFree: true
          }
        }
      },
      { Thumbs }
    )
    Fancybox.bind('[data-fancybox="gallery"]', {
      l10n: ru,
      idle: false,
      compact: false,
      dragToClose: false,

      animated: false,
      showClass: 'f-fadeSlowIn',
      hideClass: false,

      Carousel: {
        infinite: true
      },

      Images: {
        zoom: false,
        Panzoom: {
          maxScale: 1.5
        }
      },

      Toolbar: {
        absolute: true,
        display: {
          left: [],
          middle: [],
          right: ['close']
        }
      },

      Thumbs: {
        type: 'classic',
        Carousel: {
          axis: 'x',

          slidesPerPage: 1,
          Navigation: true,
          center: true,
          fill: true,
          dragFree: true,

          breakpoints: {
            '(min-width: 640px)': {
              axis: 'y'
            }
          }
        }
      }
    })
  }
}
</script>

<style>
  .fancybox__caption {
    font-size: 24px;
  }
  /* Скрыть миниатюры карусели на мобильных экранах */
  @media (max-width: 639px) {
    .f-thumbs {
      display: none;
    }
  }
</style>
