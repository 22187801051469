<template>
  <div class="pa-1">
    <div class="justify-space-between d-flex border-bottom black--text pa-1 item">
      <span>
        {{ left }}
      </span>
      <span>
      {{ right }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemInfoAdvert',
  props: {
    left: String,
    right: [String, Number]
  }
}
</script>

<style scoped>
  .item {
    gap: 3px;
  }
  @media (max-width: 300px) {
    .item {
      gap: 10px;
    }
  }
</style>
