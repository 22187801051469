<template>
  <FancyBox
    v-if="imageList.length > 0"
    class="pa-4"
    :images="imageList"
  >
  </FancyBox>
</template>

<script>
import FancyBox from '@/components/base/FancyBox'

export default {
  name: 'ViewImages',
  components: { FancyBox },
  props: {
    imageList: Array
  }
}
</script>

<style scoped>
</style>
