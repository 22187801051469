export default {
  PANUP: 'Вверх',
  PANDOWN: 'Вниз',
  PANLEFT: 'Влево',
  PANRIGHT: 'Вправо',
  ZOOMIN: 'Увеличить масштаб',
  ZOOMOUT: 'Уменьшить масштаб',
  TOGGLEZOOM: 'Переключить уровень масштабирования',
  TOGGLE1TO1: 'Переключить уровень масштабирования',
  ITERATEZOOM: 'Переключить уровень масштабирования',
  ROTATECCW: 'Поворот против часовой стрелки',
  ROTATECW: 'Поворот по часовой стрелке',
  FLIPX: 'Перевернуть по горизонтали',
  FLIPY: 'Перевернуть вертикально',
  FITX: 'Поместить горизонтально',
  FITY: 'Вертикально',
  RESET: 'Сброс',
  TOGGLEFS: 'Вкл/Выкл полноэкранный режим',
  CLOSE: 'Закрыть',
  NEXT: 'Далее',
  PREV: 'Предыдущий',
  MODAL: 'Вы можете закрыть это модальное содержимое с помощью клавиши ESC»',
  ERROR: 'Что-то пошло не так, повторите попытку позже',
  IMAGE_ERROR: 'Изображение не найдено',
  ELEMENT_NOT_FOUND: 'Элемент HTML не найден',
  AJAX_NOT_FOUND: 'Ошибка загрузки AJAX: не найден',
  AJAX_FORBIDDEN: 'Ошибка загрузки AJAX: запрещено',
  IFRAME_ERROR: 'Ошибка загрузки страницы',
  TOGGLE_ZOOM: 'Переключить уровень масштабирования',
  TOGGLE_THUMBS: 'Вкл/Выкл миниатюры',
  TOGGLE_SLIDESHOW: 'Вкл/Выкл слайд-шоу',
  TOGGLE_FULLSCREEN: 'Вкл/Выкл полноэкранный режим'
}
