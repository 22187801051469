import Advert from '@/components/structures/advert'
import validationErrors from '@/components/mixins/validationErrors'

export default {
  mixins: [validationErrors],
  data: () => ({
    isLoading: false,
    isSavingData: false,
    detail: {},
    routeName: 'myAdvertsDetail'
  }),
  methods: {
    async fillDetail () {
      this.isLoading = true
      if (this.$route.params.id) {
        this.detail = await this.$store.dispatch('server/get', { url: this.url + '/detail/' + this.$route.params.id })
      } else {
        this.detail = new Advert()
      }
      this.isLoading = false
      return true
    },
    async afterResponse (response) {
      if (!this.detail.id) {
        await this.$router.replace({ name: this.routeName, params: { id: response.id } })
      }
      this.detail = response
    },
    async handleSave () {
      if (!this.isSavingData) {
        this.isSavingData = true
        this.$v.$touch()
        await this.checkPending()
        if (!this.$v.$anyError) {
          const response = await this.$store.dispatch('server/save', {
            url: this.url + '/detail',
            data: { data: this.detail }
          })
          if (response) {
            await this.afterResponse(response)
          }
        } else {
          await this.$store.dispatch('systemMessages/error', 'Проверьте правильность заполнения полей')
        }
        this.isSavingData = false
      }
      return true
    },
    async handleDelete () {
      if (!this.isSavingData) {
        this.isSavingData = true
        const response = await this.$store.dispatch('server/delete', {
          url: this.url + '/detail?id=' + JSON.stringify([this.detail.id])
        })
        if (response) {
          await this.$router.push({ name: 'myAdverts' })
        }
        this.isSavingData = false
      }
      return true
    }
  },
  async created () {
    return this.fillDetail()
  }
}
